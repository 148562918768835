<template>
  <div style="height: inherit">
    <b-modal
      ref="my-modal-info"
      centered
      modal-class="modal-warning"
      hide-footer
    >
      <b-card-text class="word-break pre-formatted" v-html="modalInfo">
      </b-card-text>
    </b-modal>
    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Prodcuts -->
    <section class="list-view">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center w-75">
          <b-img
            :alt="`${product.title}-${product.id}`"
            fluid
            class="card-img-top w-100"
            :src="product.image"
          />
        </div>

        <!-- Product Details -->
        <b-card-body>
          <b-card-header class="d-flex flex-column align-items-start">
            <h4 class="text-right">
              {{ product.title }}
            </h4>
            <h5 class="pt-2">
              {{
                $t("Pages.Shop.Title.purchase_date : {date}",{date:MyDate.getFormatDateTime(product.purchase_date)})
              }}
            </h5>
          </b-card-header>
          <b-card-body class="border-0">
            <b-card-text class="item-company">
              <b-button variant="primary" @click="showModalInfo(product.info)">
                {{ $t("Pages.Shop.Title.order_info") }}
              </b-button>
            </b-card-text>
          </b-card-body>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price text-success">
                {{ $t("Pages.Shop.Title.amount_product") }}:
              </h4>
              <h4 class="item-price pt-1 text-dark">
                {{ product.amount.toLocaleString() }} {{ $t("Pages.Shop.Title.Tooman") }}
              </h4>
              <h4 class="item-price pt-2 text-success">
                {{ $t("Pages.Shop.Title.trans_num") }}:
              </h4>
              <h4 class="item-price pt-1 text-dark">
                {{ product.pay_id }}
              </h4>
            </div>
          </div>
        </div>
      </b-card>
    </section>

    <section>
      <b-row>
        <b-col cols="12" v-if="pages > 1">
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="pages"
            use-router
            class="mb-0"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BModal,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPaginationNav,
  BCardHeader,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import MyDate from "@core/components/date/MyDate.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPaginationNav,
    BModal,
    BCardHeader,
  },
  data() {
    return {
      MyDate,
      filters: {
        q: "",
      },
      pages: 0,
      products: [],
      modalInfo: "",
    };
  },
  created() {
    this.getProducts();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getNewData();
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getProducts() {
      this.$http.get("/api/shop/product/payed").then((response) => {
        this.products = response.data.data;
        this.pages = response.data.last_page;
      });
    },
    showModalInfo(info) {
      this.modalInfo = info;
      this.$refs["my-modal-info"].show();
    },
    getNewData() {
      this.$http
        .get("/api/shop/product/payed", {
          params: {
            page: this.$route.query.page,
          },
        })
        .then((response) => {
          this.products = response.data.data;
          this.pages = response.data.last_page;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.item-img a {
  width: 100% !important;
}

.word-break {
  word-break: break-all;
}
</style>
